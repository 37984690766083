// Scripts
import LocomotiveScroll from 'locomotive-scroll';

// Stylesheets
import "../../stylesheets/frontend/events.css";

document.addEventListener("turbolinks:load", function() {
  if (document.querySelector("body").getAttribute("id") == "eventsShow") {
    let article = document.querySelector("article");

    // procesa las imágenes del artículo quitando espacios en blanco no deseados

    let articleImgs = article.querySelectorAll("p > img, p picture img.desktop-img");

    for (let img of articleImgs) {
      let imgWrapper = img.parentNode;

      let source = imgWrapper.querySelector("source");

      if (source) {
        imgWrapper.parentNode.innerHTML = imgWrapper.parentNode.innerHTML.replace(/&nbsp;/g, '');
      }
    }
  }

  // inicializa Locomotive Scroll
  if (document.querySelector("body").getAttribute("id") == "eventsShow") {
    let scroller = new LocomotiveScroll({
      el: document.querySelector('body'),
      smooth: true,
      speed: 3,
      tablet: {
        smooth: true,
        speed: 35,
      },
      smartphone: {
        smooth: true,
        speed: 35,
      }
    });
  }
});
