// Stylesheets
import "../../stylesheets/frontend/home.css";

document.addEventListener("turbolinks:load", function() {
  if (document.querySelector("body").getAttribute("id") == "staticPagesHome") {
    new Swiper("#headerSlider", {
      slidesPerView: 1,
      speed: 1000,
      centeredSlides: false,
      loop: true,
      freeModeMomentum: false,
      preventInteractionOnTransition: true,
      threshold: 0.05,
      effect: "fade",
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 5000,
      },
    });
  }
});
