// Scripts
import "../scripts/global.js";
import "../scripts/frontend/home.js";
import "../scripts/frontend/events.js";
import "../scripts/frontend/participants.js";

// Stylesheets
import "../stylesheets/frontend.css";
import "../stylesheets/frontend/home.css";
import "../stylesheets/frontend/manifest.css";
import "../stylesheets/frontend/encapsulart.css";

// Components
import "../components/menu/menu";
import "../components/cookies_modal/cookies_modal";
import "../components/gallery/gallery";
import "../components/gallery_viewer/gallery_viewer";
import "../components/video_viewer/video_viewer";
import "../components/event_card/event_card";
import "../components/participant_card/participant_card";
import "../components/multimedia_card/multimedia_card";
import "../components/infinite_scroll/infinite_scroll";
import "../components/social_media/social_media";
import "../components/langs_select/langs_select";
import "../components/footer/footer";

let prevWindowWidth = window.innerWidth;

document.addEventListener("turbolinks:load", function() {
  // recarga la página al redimensionarla y cruzar determinado umbral
  // para forzar que algunos elementos se ajusten correctamente

  let bodyId = document.querySelector("body").getAttribute("id");
  let watchedPages = ["staticPagesHome", "eventsShow", "participantsShow"];

  if (watchedPages.indexOf(bodyId) > -1) {
    window.addEventListener("resize", debounce(function() {
      let windowWidth = window.innerWidth;

      if ((prevWindowWidth <= 1024 && windowWidth > 1024) || (prevWindowWidth > 1024 && windowWidth <= 1024)) {
        prevWindowWidth = windowWidth;
        location.reload();
      }
    }, 25));
  }

  // newsletter
  let newsletterForm = document.querySelector("#newsletterForm");

  if (newsletterForm) {
    newsletterForm.addEventListener("ajax:beforeSend", function(event) {
      event.target.classList.add("sending");
    });

    newsletterForm.addEventListener("ajax:success", function(event) {
      let form = event.target;

      form.classList.remove("sending", "error");
      form.classList.add("done");

      form.querySelector(".error").classList.remove("visible");

      let submitButton = form.querySelector(".submit");
      let SUCCESS_MSG = {"es": "¡Suscrito!", "ca": "Subscrit!", "en": "Subscribed!"};

      submitButton.querySelector("span").innerText = SUCCESS_MSG[document.documentElement.lang];

      form.reset();
    });

    newsletterForm.addEventListener("ajax:error", function(event) {
      let form = event.target;

      form.querySelector(".error").classList.add("visible");

      form.classList.remove("sending");
      form.classList.add("error");
    });
  }
});
