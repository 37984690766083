// Stylesheets
import "../../stylesheets/frontend/participants.css";
import Macy from "../macy/macy.js";

let prevWindowWidth = window.innerWidth;

function loadParticipatsIndexSlider() {
  if (window.innerWidth > 1300) {
    let offset = document.querySelector("header").offsetWidth;

    new Swiper("#participantsSlider", {
      slidesPerView: "auto",
      slidesOffsetBefore: offset,
      slidesOffsetAfter: 0,
      speed: 100,
      freeModeMomentum: false,
      freeMode: true,
      mousewheel: {
        releaseOnEdges: true,
      },
      centeredSlides: false,
      loop: false,
      preventInteractionOnTransition: true,
      threshold: 0.2,
    });
  }
  else {
    new Swiper("#participantsSlider", {
      slidesPerView: "auto",
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      speed: 350,
      centeredSlides: false,
      loop: false,
      preventInteractionOnTransition: true,
      threshold: 0.05,
    });
  }
}

function macyColumns(cols) {
  let screenWidth = window.innerWidth;

  if (screenWidth <= 1024) {
    return 2;
  }
  else {
    return cols;
  }
}

document.addEventListener("turbolinks:load", function() {
  if (document.querySelector("body").getAttribute("id") == "participantsIndex") {
    loadParticipatsIndexSlider();

    window.addEventListener("resize", debounce(function() {
      let windowWidth = window.innerWidth;

      if ((prevWindowWidth <= 1300 && windowWidth > 1300) || (prevWindowWidth > 1300 && windowWidth <= 1300)) {
        prevWindowWidth = windowWidth;
        location.reload();
      }
    }, 25));
  }

  if (document.querySelector("body").getAttribute("id") == "participantsShow") {
    window.setTimeout(function() {
      Macy({
        container: ".grid",
        columns: macyColumns(3)
      });
    }, 100);
  }
});
